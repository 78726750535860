<template>
  <div class="create-page" v-if="!steps.length">
    <CForm
      v-on:submit.prevent="createSingleStep"
      novalidate
      class="create-page__form"
    >
      <FormBuilder
        ref="form"
        :list="formList"
        @updated="updateSettingsData"
        :page="{ title: texts.createPage.title, info: descriptions }"
      />
    </CForm>
  </div>
</template>

<script>
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import fieldHelpers from '@/utils/ZiqniFieldHelper';
import fileRepositoryFields from '@/generated/ziqni/store/modules/fileRepositories/fields';
import { mapGetters, mapActions } from 'vuex';
import { stepSubTitles } from '@/config/pageTexts/stepSubTitles.json';
import { fileRepositories } from '@/config/descriptions/fileRepositories.json';
import { fileRepositoriesTexts } from '@/config/pageTexts/fileRepositories.json';

export default {
  name: 'CreateFileRepository',
  components: {},
  data() {
    return {
      model: 'fileRepositories',
      currentStep: 0,
      totalStep: 0,
      descriptions: {
        ...fileRepositories.create,
      },
      texts: {
        ...fileRepositoriesTexts,
      },
      steps: [],
      stepKeys: [],
      formList: [],
      settingsData: {},
      requiredFields: [],
    };
  },
  computed: {
    ...mapGetters('fileRepositories', ['message', 'loading']),
    isButtonDisabled() {
      if (this.loading) return true;
      return !!this.message;
    },
  },
  provide() {
    return {
      stepKeys: this.stepKeys,
      model: this.model,
    }
  },
  watch: {
    message(val) {
      if (val === this.texts.createPage.duplicateMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      } else if (val === this.texts.createPage.emptyMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(
      'fileRepositories',
      [
        'handleCreateFileRepositories',
      ]
    ),
    initialize() {
      routerBreadcrumbs(this.$router.currentRoute);

      this.formList = fieldHelpers.prepareCreateFormList(
        fileRepositoryFields,
        fileRepositoriesTexts.createPage,
        fileRepositories.create
      );

      this.formList.unshift({
        "key": "published",
        "label": "Public",
        "tooltip": "An ON/OFF switch indicating if the web asset is public and accessible",
        "type": "SWITCH",
        "model": "CreateFileRepositoryRequest",
        "value": false,
        "disabled": false,
        "options": null,
        "multiple": false,
        "required": true,
        "showKey": false
      })

      let formSteps = [];
      this.formList.forEach(field => {
        if (field.type.indexOf('_OBJECT') !== -1) {
          formSteps.push(field);
        }
        if (field.required) {
          this.requiredFields.push(field.key);
          if (field.type === 'NAMEKEY') {
            this.requiredFields.push('name');
          }
        }
      })

      if (formSteps.length) {
        let objectTypes = [];
        formSteps.forEach(step => {
          objectTypes.push(step.type)
          if (step.key === 'scheduling') {
            this.requiredFields.scheduling = [];
            this.requiredFields.scheduling.push('scheduleType')
          }
        });
        this.formList = this.formList.filter(formItem => {
          return !objectTypes.includes(formItem.type)
        });

        this.steps.push(this.firstStep);
        let stepNumber = 1

        formSteps.forEach(step => {
          this.steps.push({
            title: step.label,
            subTitle: stepSubTitles[step.key],
            key: step.key,
            step: stepNumber,
          });
          this.stepKeys.push(step.key);

          stepNumber++;
        })

        this.translatableFields = fileRepositoryFields.baseFields.translatableFields;

        this.lastStep.step = stepNumber;
        this.steps.push(this.lastStep);
        this.totalStep = formSteps.length + 1;
      }
    },
    getInvalidFields() {
      let result = [];
      this.settingsData.constraints = [];

      for (let key in this.settingsData) {
        if (this.requiredFields.includes(key) && (this.settingsData[key] === null || this.settingsData[key] === '')) {
          result.push(key);
        }
      }

      return result;
    },
    updateSettingsData(val) {
      this.settingsData = val;
      this.toggleHostingOptionsFields(this.settingsData.published);
    },
    setInvalidFields(invalidFields) {
      invalidFields.forEach(invalidField => {
        let invalidFieldElement = document.getElementsByName(invalidField)[0];
        let invalidElement = invalidFieldElement;
        if (!invalidFieldElement.classList.contains('zq--form-row')) {
          invalidElement = invalidFieldElement.parentNode;
        }
        invalidElement.classList.add('zq-invalid');
      })
    },
    toggleHostingOptionsFields(isPublished = false) {
      let hostingOptionsElem = this.$refs.form.$el.querySelector('#zqHostingOptions')
      if (isPublished) {
        hostingOptionsElem.classList.remove('isHidden');
      } else {
        hostingOptionsElem.classList.add('isHidden');
      }
    },
    createSingleStep() {
      let invalidFields = this.getInvalidFields();
      if (!invalidFields.length) {
        this.createEntity();
      } else {
        this.setInvalidFields(invalidFields)
      }
    },
    createEntity() {
      let formData = {};

      if (Object.keys(this.settingsData).length) {
        formData = {...this.settingsData};
      }

      if (formData.key && typeof formData.key === 'object') {
        formData.name = formData.key.name;
        formData.key = formData.key.key;
      }

      formData.hostingOptions = {indexFileName: formData.indexFileName, errorFileName: formData.errorFileName}
      delete formData.indexFileName
      delete formData.errorFileName

      if (formData.published) {
        formData.constraints.push('public')
      }
      delete formData.published;

      const body = [];
      body.push(JSON.parse(JSON.stringify(formData)));

      this.handleCreateFileRepositories({createFileRepositoryRequestArray: body})
        .then(data => {
          if (data.length) {
            this.$router.push({
              name: 'PreviewFileRepository',
              params: {
                id: data[0].id,
              }
            })
          } else {
            console.log('Something went wrong');
          }
        });
    },
  },
};
</script>

<style lang="scss">
.create-page {
  &__header {
    background-color: var(--zq-main-bg);
  }
  .zq-invalid {
    .form-control {
      border: solid 1px var(--zq-warn);
    }
  }
  .isHidden {
    display: none;
  }
}
</style>
